// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jak-korzystac-jsx": () => import("./../../../src/pages/jak-korzystac.jsx" /* webpackChunkName: "component---src-pages-jak-korzystac-jsx" */),
  "component---src-pages-konkurs-jsx": () => import("./../../../src/pages/konkurs.jsx" /* webpackChunkName: "component---src-pages-konkurs-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-o-edumapie-jsx": () => import("./../../../src/pages/o-edumapie.jsx" /* webpackChunkName: "component---src-pages-o-edumapie-jsx" */),
  "component---src-pages-scenariusze-jsx": () => import("./../../../src/pages/scenariusze.jsx" /* webpackChunkName: "component---src-pages-scenariusze-jsx" */),
  "component---src-templates-track-jsx": () => import("./../../../src/templates/track.jsx" /* webpackChunkName: "component---src-templates-track-jsx" */)
}

